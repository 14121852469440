import React from 'react';
import { ICONS } from 'src/utils/icons';
import { Link } from 'gatsby';

function TitleBar(props: {
    title: string;
    backTo: string;
    clear?: {
        filterString: string;
        setFilterString(s: string): void;
    };
}) {
    return (
        <div className='w-full mb-6 text-gray-800 centering-row sm:mb-8'>
            <Link to={props.backTo}>
                <div className='flex-shrink-0 w-8 h-8 p-1'>{ICONS.back}</div>
            </Link>
            <div className='self-stretch flex-grow' />
            <div className='ml-2 text-2xl text-center capitalize sm:mr-10 font-weight-600'>
                {props.title}
            </div>
            <div className='self-stretch flex-grow' />
            {props.clear && (
                <div
                    className={
                        'w-8 h-8 p-1 transition-opacity duration-100 flex-shrink-0 ' +
                        (props.clear.filterString === ''
                            ? 'opacity-0'
                            : 'opacity-50 cursor-pointer')
                    }
                    onClick={() => props.clear.setFilterString('')}
                >
                    {ICONS.clear}
                </div>
            )}
        </div>
    );
}

export default TitleBar;
