import React, { useState } from 'react';
import { ICONS } from 'src/utils/icons';
import { ProjectSectionObject } from 'src/utils/types';
import useEvent from '../../utils/use-event';

export default function ProjectImageSlider(props: {
    close(): void;
    sliderIndex: number;
    setSliderIndex(index: number): void;
    sliderImages: ProjectSectionObject[];
    projectTitle: string;
}) {
    const {
        close,
        sliderIndex,
        setSliderIndex,
        sliderImages,
        projectTitle,
    } = props;

    useEvent('keydown', keydown);
    const [commentVisible, setCommentVisible] = useState(true);

    function keydown(e: KeyboardEvent) {
        switch (e.key) {
            case 'Escape':
                close();
                break;
            case 'ArrowLeft':
                prevImage();
                break;
            case 'ArrowRight':
                nextImage();
                break;
            default:
                break;
        }
    }

    function prevImage() {
        setSliderIndex(
            sliderIndex === 0 ? sliderImages.length - 1 : sliderIndex - 1
        );
    }

    function nextImage() {
        setSliderIndex((sliderIndex + 1) % sliderImages.length);
    }

    const Stretch = () => <div className='self-stretch flex-grow' />;
    const Icon = (props: {
        onClick(): void;
        children: React.ReactNode;
        className?: string;
    }) => (
        <div
            className={
                'text-white transition-opacity duration-150 hover:opacity-50 opacity-90 ' +
                `cursor-pointer w-10 h-10 p-2 m-1 sm:m-2 ${props.className} drop-shadow ` +
                'flex-shrink-0 '
            }
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );

    const commentExists: Boolean = // @ts-ignore
        sliderImages[sliderIndex]?.subtitle != null && // @ts-ignore
        sliderImages[sliderIndex]?.subtitle != '';

    let media: React.ReactNode;
    const sectionComponent: any = sliderImages[sliderIndex];

    switch (sectionComponent?.strapi_component) {
        case 'project-blog-components.image':
            media = (
                <img
                    className='object-contain w-full h-full overflow-hidden'
                    // @ts-ignore
                    src={sectionComponent?.image.url}
                    alt={`${projectTitle} media ${sliderIndex + 1}`}
                    width={sectionComponent?.image.width}
                    height={sectionComponent?.image.height}
                />
            );
            break;
        case 'project-blog-components.video':
            media = (
                <video
                    className='max-w-full max-h-full p-2 overflow-hidden'
                    // @ts-ignore
                    src={sliderImages[sliderIndex]?.video.url}
                    controls
                />
            );
            break;
    }

    return (
        <div
            className={
                'fixed top-0 left-0 py-2 px-0 bg-gray-900 ' +
                'w-screen h-screen centering-row z-50 no-selection '
            }
        >
            <Icon onClick={close} className='absolute top-0 right-0'>
                {ICONS.clear}
            </Icon>

            <div className='absolute bottom-0 right-0 px-3 py-2 text-lg text-white font-weight-600 drop-shadow '>
                {sliderIndex + 1}/{sliderImages.length}
            </div>
            <Icon onClick={prevImage}>{ICONS.left}</Icon>
            <Stretch />
            {media}
            <Stretch />
            <Icon onClick={nextImage}>{ICONS.right}</Icon>
            <div className={'absolute bottom-0 left-0 w-full max-w-xl'}>
                {commentExists && (
                    <>
                        {!commentVisible && (
                            <div
                                className={
                                    'absolute bottom-0 left-0 z-0 m-2 p-1 text-xl cursor-pointer ' +
                                    'transition-opacity duration-150 hover:opacity-60 opacity-90 '
                                }
                                onClick={() => setCommentVisible(true)}
                            >
                                💬
                            </div>
                        )}
                        <div
                            className={
                                'absolute bottom-0 m-2 left-0 overflow-hidden z-10 ' +
                                'bg-gray-100 rounded shadow ring-rose-600 ' +
                                'text-xl font-weight-500 centering-row p-3 ring-2 ' +
                                (commentVisible
                                    ? 'scale-x-100 '
                                    : 'scale-x-0 ') +
                                'transform transition-all duration-200 origin-left'
                            }
                        >
                            <div className='text-justify font-crimson-pro'>
                                {/* @ts-ignore */}
                                {sliderImages[sliderIndex]?.subtitle}
                            </div>
                            <div
                                className={
                                    'flex-shrink-0 w-10 h-10 p-2 ml-3 cursor-pointer ' +
                                    'transition-opacity duration-150 hover:opacity-50 opacity-90 '
                                }
                                onClick={() => setCommentVisible(false)}
                            >
                                {ICONS.clear}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
