import React from 'react';
import { ProjectImageObject } from '../../utils/types';

export default function ProjectImageCard(props: {
    node: ProjectImageObject;
    openSlider(): void;
}) {
    const { node } = props;

    return (
        <>
            <div
                className={
                    'relative w-full flex flex-col rounded shadow overflow-hidden '
                }
            >
                <img
                    width={node.image.width}
                    height={node.image.height}
                    src={node.image.url}
                    className='w-full cursor-pointer'
                    onClick={props.openSlider}
                    alt={`project image: ${node.subtitle}`}
                />
                {node.subtitle && node.subtitle.length > 0 && (
                    <div
                        className={
                            'w-full px-4 sm:px-6 py-2 ' +
                            'text-lg font-weight-500 ' +
                            'text-gray-300 bg-gray-800'
                        }
                    >
                        {node.subtitle}
                    </div>
                )}
            </div>
        </>
    );
}
