import React from 'react';
import { ProjectTextObject } from '../../utils/types';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import 'src/styles/markdown-content.css';
import { Link } from 'gatsby';

export default function ProjectTextCard(props: { node: ProjectTextObject }) {
    const { node } = props;

    return (
        <section
            className={
                'relative w-full markdown text-xl ' +
                'rounded bg-white text-gray-800 shadow ' +
                'p-4 sm:p-6 '
            }
        >
            <ReactMarkdown
                skipHtml={false}
                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                components={{
                    h1: ({ node, ...props }) => (
                        <h1
                            id={
                                props.id
                                    ? // @ts-ignore
                                      props.id.replace('user-content-', '')
                                    : ''
                            }
                        >
                            {props.children}
                        </h1>
                    ),
                    h2: ({ node, ...props }) => (
                        <h2
                            id={
                                props.id
                                    ? // @ts-ignore
                                      props.id.replace('user-content-', '')
                                    : ''
                            }
                        >
                            {props.children}
                        </h2>
                    ),
                    a: ({ node, ...props }) =>
                        // @ts-ignore
                        props.href.includes('#') ? (
                            // @ts-ignore
                            <Link to={props.href} target='_self'>
                                {props.children}
                            </Link>
                        ) : (
                            <a
                                {...props}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='inline text-left underline'
                            >
                                {props.children}
                            </a>
                        ),
                }}
            >
                {node.text}
            </ReactMarkdown>
        </section>
    );
}
