import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { ICONS } from 'src/utils/icons';
import { ProjectObject, ProjectSectionObject } from 'src/utils/types';
import ProjectTextCard from 'src/components/project/project-text-card';
import ProjectImageCard from 'src/components/project/project-image-card';
import { ProjectImageObject } from '../utils/types';
import { useEffect } from 'react';
import ProjectImageSlider from 'src/components/project/project-image-slider';
import ProjectVideoCard from 'src/components/project/project-video-card';
import TitleBar from 'src/components/title-bar';
import MainContainer from 'src/components/main-container';

export default function Project({ data }) {
    const project: ProjectObject = data.strapiProject;

    useEffect(() => {
        let imageIndex = 0;
        for (let i = 0; i < project.blog.length; i++) {
            if (
                project.blog[i].strapi_component ==
                    'project-blog-components.image' ||
                project.blog[i].strapi_component ==
                    'project-blog-components.video'
            ) {
                // @ts-ignore
                project.blog[i].imageIndex = imageIndex++;
            }
        }
    }, [project]);

    const [sliderVisible, setSliderVisible] = useState(false);
    const [sliderIndex, setSliderIndex] = useState(0);

    function openSlider(index: number) {
        setSliderIndex(index);
        setSliderVisible(true);
        document.body.style.overflow = 'hidden';
    }

    function closeSlider() {
        setSliderVisible(false);
        document.body.style.overflow = 'scroll';
    }

    // @ts-ignore
    const sliderImages: ProjectImageObject[] = project.blog.filter(
        node =>
            node.strapi_component === 'project-blog-components.image' ||
            node.strapi_component === 'project-blog-components.video'
    );

    return (
        <MainContainer>
            {sliderImages.length > 0 && sliderVisible && (
                <ProjectImageSlider
                    close={closeSlider}
                    {...{ sliderIndex, sliderImages, setSliderIndex }}
                    projectTitle={project.title}
                />
            )}

            <TitleBar title={project.title} backTo='/projects' />

            <article className='flex flex-col w-full max-w-xl space-y-3'>
                {project.blog.map(
                    (node: ProjectSectionObject, index: number) => {
                        switch (node.strapi_component) {
                            case 'project-blog-components.text':
                                return (
                                    <ProjectTextCard key={index} node={node} />
                                );
                            case 'project-blog-components.image':
                                return (
                                    <ProjectImageCard
                                        key={index}
                                        node={node}
                                        openSlider={() =>
                                            openSlider(node.imageIndex)
                                        }
                                    />
                                );
                            case 'project-blog-components.video':
                                return (
                                    <ProjectVideoCard key={index} node={node} />
                                );
                            default:
                                return <React.Fragment />;
                        }
                    }
                )}
            </article>
        </MainContainer>
    );
}

export const query = graphql`
    query($identifier: String!) {
        strapiProject(identifier: { eq: $identifier }) {
            title
            blog {
                strapi_component
                image {
                    url
                    width
                    height
                }
                subtitle
                text
                video {
                    url
                }
            }
        }
    }
`;
